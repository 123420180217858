.community-management-layout {
  .community-header-region {
    .community-header {
      margin: @ax-spacing-s 0 @ax-spacing-xxl;

      &__icon {
        .size(4.8rem);
        margin-right: @ax-spacing-m;

        .ax-breakpoint-m({
          .size(7.2rem);
        });

      img {
        max-height: 100%;
        .border-radius(@ax-border-radius-s);
      }

      &.icon-communities {
        padding: @ax-spacing-s;
        font-size: 3.2rem;

        .ax-breakpoint-m({
          padding: 1.2rem;
          font-size: 4.8rem;
        });

      background-color: @ax-color-grey-30;
      color: @ax-color-grey-60;
      .border-radius(@ax-border-radius-s);
    }
  }
}

.community-breadcrumb {
  overflow: hidden;

  .all-crumb {
    color: inherit;

    &.focus-visible {
      .ax-focus-keyboard();
    }
  }
}

.mobile-breadcrumb-flex {
  .flex(1 1 50%);

  .ax-breakpoint-m({
    .flex(1 1 0)
  });
}
}

.tabbed-layout .tab-container {
  margin-bottom: @ax-spacing-xl;
}

.general-settings-form {
  label {
    display: block;
    font-weight: @ax-font-weight-medium;
  }

  .language-input {
    width: 50%;
  }

  &__name.rederror .language-input {
    border: 0.1rem solid @error-state;
  }

  &__code {
    margin-bottom: @ax-spacing-m;
  }

  &__type,
  &__name,
  &__image {
    margin-bottom: @ax-spacing-xxl;
  }

  .adjust-photo-wrap {
    padding-left: 0;
  }

  .image-crop .cropped-image-upload .preview-wrap.no-image {
    background-repeat: no-repeat;

    .thumbnail-preview {
      background-image: url('@common/assets/images/community_thumbnail_200.png');
      background-size: 200px;
    }

    .thumbnail-preview-size-1 {
      background-image: url('@common/assets/images/community_thumbnail_50.png');
      background-position: unset;
      background-size: 50px;
    }

    .thumbnail-preview-size-2 {
      background-image: url('@common/assets/images/community_thumbnail_40.png');
      background-position: unset;
      background-size: 40px;
    }
  }

  &__switch {
    .switch-label {
      margin-bottom: @ax-spacing-m;
    }
  }

  .fr-second-toolbar {
    display: none;
  }

  .thumbnail-preview {
    z-index: -1;
  }
}

.access-settings-form {
  .group-button {
    margin-bottom: @ax-spacing-m;
  }

  .table-layout {
    th {
      &:first-child {
        width: 40%;
      }

      &:not(:first-child) {
        width: 10%;
        padding: 0;
        text-align: center;
      }
    }

    td {
      &:not(:first-child) {
        padding-left: 0;
      }
    }

    &__col1 {
      width: 40%;
      max-width: 70rem;

      span.group-name {
        .full-width();
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__col2 {
      width: 40%;

      ul {
        margin: 0;

        li {
          margin: 0;
          text-align: center;
        }
      }
    }

    &__col3 {
      width: 20%;
      text-align: center;

      button {
        color: @ax-color-grey-60;

        &:hover {
          color: @ax-color-grey-80;
        }

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }
    }
  }

  .team-selector {
    margin-bottom: @ax-spacing-xl;
    width: 50%;

    .filterable-selector-label {
      font-size: 1.6rem;
      margin-bottom: @ax-spacing-xs;
    }
  }

  input:disabled {
    cursor: default;
  }
}

.content-settings-form {
  // adding whitespace on the bottom of the page
  padding-bottom: 10rem;

  .content-settings__text {
    font-weight: @ax-font-weight-regular;
    margin: 0 0 @ax-spacing-m;
    font-size: @ax-font-size-s;
  }

  .content-settings__text--secondary {
    color: @ax-color-grey-60;
    margin-top: @ax-spacing-m;
  }

  .publish-dropdown {
    .display-flex();
    .flex-direction(column);
  }
}
}

.group-access-modal {
  .group-list {
    margin: @ax-spacing-s @ax-spacing-l;
    height: calc(100vh - 23rem);
    min-height: 23rem;

    .icon-remove {
      top: 5.4rem;
      right: @ax-spacing-xxl;

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }

    .selectableList {
      height: calc(100% - 9rem);
      border-bottom: 0.1rem solid @ax-color-grey-40;

      label {
        margin: 0;
      }
    }

  }
}

.communities-management-layout {
  .communities-search {
    margin-top: @ax-spacing-xxl;

    &__add--button {
      .icon-plus {
        .opacity(1);
        color: @ax-color-white;
      }
    }

    .communities-count-text,
    .communities-search-filters {
      margin-top: @ax-spacing-xl;
      color: @ax-color-grey-60;
    }

    .communities-count-text {
      font-size: @ax-font-size-xs;
      text-align: right;
    }

    .communities-search-filters {
      margin: @ax-spacing-xl 0 0;

      input {
        .size(@_ax-font-size-label);

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }
    }

    .communities-search__menu {
      margin-left: @ax-spacing-s;

      .menu-dropdown__popover {
        min-width: 30rem;
      }

      .menu-dropdown__button-container .menu-dropdown__default-secondary-button {
        min-height: auto;
      }
    }
  }

  .tags-search {
    margin-top: @ax-spacing-xxl;

    .tags-count-text {
      color: @ax-color-grey-60;
      font-size: @ax-font-size-xs;
      text-align: right;
    }
  }

  .tabbed-layout .tab-container {
    .ax-breakpoint-m({
      padding: 0 @ax-spacing-xs;
    })
}

.table-layout {
  thead th {
    text-align: left;
  }

  td {
    &.code-text-break {
      word-break: break-all;
      word-wrap: break-word;
    }

    button.delete-community,
    button.settings-redirect,
    button.community-navigate,
    button.archive-community,
    button.restore-community {
      &:focus {
        .ax-focus-keyboard();
      }

      &:hover span {
        color: @ax-color-grey-80;
      }

      span {
        cursor: pointer;
        opacity: 1;
        color: @ax-color-grey-60;
      }
    }
  }
}
}

.integration-access-view {
  min-height: 30rem;

  h2 {
    font-weight: @ax-font-weight-bold;
    margin-bottom: 1rem;
  }

  .add-btn {
    margin-bottom: 1rem;
  }

  table td {
    cursor: pointer;

    &:hover {
      background-color: @element-hover-bright;
    }
  }
}

#modalview.api-key-modal {
  overflow: visible;

  .scrollable {
    //override the default modal styling
    overflow: visible;
  }
}

.api-modal {
  .border-radius(@ax-border-radius-m);
  background: @white;

  .adjustable-container {
    z-index: 5 !important;
  }

  .modal-form {
    padding: 1.5rem;
    font-size: @ax-font-size-body;
  }

  .modal-form.is-new {
    padding-bottom: 0;
  }

  .title {
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
    background: @primary-bg;
    padding: 1rem;
  }

  .user-container {
    .search-container {
      .autocompletemenu {
        background: @white;
        border: .1rem solid @secondary-grey;
        display: none;

        li {
          padding: 1rem;
          border-bottom: .1rem solid @accent-grey;
          .clickable;
        }

        a {
          color: @ax-color-text;
        }
      }
    }
  }

  .name,
  .owner,
  .api,
  .api-input,
  .user-warning {
    width: 98%;
    margin-bottom: 0;
  }

  .user-name,
  .name-input,
  .user-container {
    margin-top: 0;
    margin-bottom: 1.5rem;
    width: 98%;
  }

  .user-container.is-new {
    margin-bottom: 0;
  }
}

.confirm-extra-content-inner {
  margin: 0 @ax-spacing-l;
  padding: @ax-spacing-m;
  background: @_accent-grey;
  text-align: left;
}

.archive-confirm-modal {
  .community-icon {
    .size(3rem);
  }
}

.community-type-toggle {
  .business-unit-section {

    label,
    .text-muted {
      cursor: default !important;
    }

    label:hover,
    .text-muted:hover {
      cursor: default !important;
    }
  }

  .ax-radio:first-child label {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
  }

  input[type='radio'] {
    transform: scale(1.5);
    margin: @ax-spacing-xs 2rem 0;
  }

  label {
    font-weight: @ax-font-weight-regular;
    font-size: @ax-font-size-s;
  }

  input:hover,
  label:hover {
    cursor: pointer;
  }

  li {
    margin-top: @ax-spacing-s;
  }

  p {
    margin: @ax-spacing-s 0 @ax-spacing-s;
  }
}

.include-all-subteams {
  margin-bottom: @ax-spacing-xl;

  .include-all-subteams__primary-text {
    margin-bottom: @ax-spacing-xs;
  }

  .include-all-subteams__secondary-text {
    line-height: @ax-line-height-xs;
    font-size: @ax-font-size-body;
    margin-bottom: 0;
  }
}

.import-history .table-layout td:first-child {
  width: 25%;
  word-break: break-all;
  word-wrap: break-word;
}

.translations-settings-form {
  hr {
    &:first-of-type {
      margin-bottom: @ax-spacing-m;
    }

    &:nth-of-type(2) {
      margin-top: @ax-spacing-l;
    }
  }

  .disclaimer__wrapper {
    position: relative;
    display: inline-block;
    margin-top: @ax-spacing-xs;

    .icon-info_sign {
      color: @primary-blue;

      &:hover {
        cursor: pointer;
      }

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }

    .disclaimer {
      position: absolute;
      box-shadow: @ax-box-shadow-l;
      background: @white;
      padding: @ax-spacing-l;
      margin-top: @ax-spacing-xs;
      width: 40rem;
      left: 16rem;

      h3 {
        margin-top: 0;
      }
    }
  }

  .disclaimer__google-disclaimer {
    background: url('@training/assets/images/google_disclaimer_coloured_regular.svg') center/cover;
    width: 176px;
    height: 16px;
    display: inline-block;
  }
}

@import "tagGroupFilters/TagGroupFiltersTabView.less";
