/*******************************************************************************
* Container
*/

/**
 *
 * The contents of this mixin has been extended from icomoon's style.less file; we
 * need this to help override the checkbox and expand/collapse states in the
 * fancytree component.
 *
 * If you are thinking of using :extend be very careful - The main problem with extend
 * can be with nested styles that lead to that css bloat.
 *
 */
.iconify {
  &:extend([class^="icon-"]);
}

table,
ul {
  &.fancytree-container {
    background-color: transparent;
    border: 1px solid transparent;
    height: 100%;
    .box-sizing(border-box);
    overflow: auto;
    padding-left: 0.5em;

    a {
      font-size: inherit;
    }

    tr,
    ul {
      box-sizing: border-box;

      td,
      li {
        position: relative;
      }
    }
  }
}

/*******************************************************************************
* Nodes
*/

span.fancytree-node {
  width: 100%;
  margin: 0;
  padding-left: 0.5em;
  margin: 1px 0;

  &:hover {
    .transition(background-color, @ax-transition-speed);
    background-color: @subtle-hover;
  }

  span.fancytree-checkbox {
    margin-top: 6px;
    cursor: pointer;
    width: @ax-font-size-m;
    height: @ax-font-size-m;

    background: none;
    .border-radius(@ax-spacing-xs);
    border: 0.1rem solid @ax-color-grey-50;
    color: @white;

    line-height: @ax-font-size-m;
    text-align: center;
    vertical-align: middle;
    .iconify();
  }

  span.fancytree-expander,
  span.fancytree-expander:hover,
  span.fancytree-connector {
    display: inline-block;
    background: none;

    width: 25px;
    height: 25px;
    .box-sizing(content-box);
    line-height: @ax-font-size-m !important;
    text-align: center;
    vertical-align: middle;
    .iconify();
    .icon-caret_right();

    .rtl-only({
      .icon-caret_left();
    });
  font-size: @ax-font-size-xl;

  &:before {
    line-height: 2.5rem;
  }
}

span.fancytree-connector {
  background-image: none;
  cursor: pointer;
  border: none;
  margin: 0;
  width: 5px;
  .border-radius(0px);
  background-color: @white;
  height: 30px;
  margin-left: 3px;
}

.screen-md-portrait-min({
  &.fancytree-focused span.fancytree-title {
    outline-color: transparent;
    outline-style: solid;
    outline-width: 1px;
  }

  span.fancytree-checkbox {
    margin-top: 0;
  }

  span.fancytree-connector {
    width: 10px;
  }
});

&:not(.fancytree-has-children) {
  span.fancytree-expander {
    visibility: hidden;
  }
}

&.fancytree-selected {
  &:not(.root) {
    background-color: transparent;
  }
}
}

.fancytree-selected span.fancytree-checkbox,
.fancytree-partsel span.fancytree-checkbox {
  border: 0.1rem solid @ax-color-blue-60 !important; // reserve some space for status borders
  background-color: @ax-color-blue-60 !important;
  .icon-minus() !important;
}

.fancytree-selected span.fancytree-checkbox,
.fancytree-partsel.fancytree-selected span.fancytree-checkbox {
  .icon-ok() !important;
}

tr.root span.fancytree-node {
  display: block;
  color: @black;

  .fancytree-title {
    line-height: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    color: @black;
  }

  .counter {
    color: @meta-color;
    display: block;
    padding-left: @ax-spacing-xl;
    padding-bottom: @ax-spacing-xxs;
  }

  span.fancytree-expander,
  span.fancytree-expander:hover,
  span.fancytree-connector {
    float: left;
    display: inline-block;
    background: none;

    &:before {
      vertical-align: middle;
    }

    width: 2.5rem;
    height: 2.5rem;
    .box-sizing(content-box);
    .iconify();
    .icon-angle_up();
    font-size: 100%;
    cursor: pointer;
  }
}

span.fancytree-node.root {
  display: block;
  color: @black;
  border-bottom: 1px solid @secondary-bg;

  .fancytree-title {
    line-height: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    color: @black;
  }

  .counter {
    color: @meta-color;
    display: block;
    padding-left: @ax-spacing-xl;
    padding-bottom: @ax-spacing-xxs;
  }

  span.fancytree-expander,
  span.fancytree-expander:hover,
  span.fancytree-connector {
    float: left;
    display: inline-block;
    background: none;

    &:before {
      vertical-align: middle;
    }

    width: 2.5rem;
    height: 2.5rem;
    .box-sizing(content-box);
    .iconify();
    .icon-angle_up();
    font-size: 100%;
    cursor: pointer;
  }
}

tr.root span.fancytree-node.fancytree-node--no-space-bottom {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

tr.root.fancytree-expanded span.fancytree-expander {
  .icon-angle_down() !important;
}

span.fancytree-node,
tr.fancytree-has-children {
  &.fancytree-expanded span.fancytree-expander {
    .iconify();
    .icon-caret_down();

    .rtl-only({
      .iconify();
      .icon-caret_down();
    });
}
}

tr.root.fancytree-has-children {
  &.fancytree-expanded {
    background-color: @ax-color-grey-10;
  }
}

table.fancytree-container {
  white-space: nowrap;

  tr.fancytree-has-children {
    border-bottom: 0.1rem solid @secondary-bg;

    span.fancytree-expander {
      visibility: visible;
    }
  }

  tr td:hover {
    .transition(background-color, @ax-transition-speed);
    background-color: @subtle-hover !important;
  }

  // No need for a hover-background on nodes that don't have any content
  tr:not([class^='fancytree-'])>td:hover,
  tr:not([class^='fancytree-'])>td>span:hover {
    background-color: transparent !important;
  }

  // overriding default table styles
  td {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@-moz-document url-prefix() {
  span.fancytree-node {
    display: block;
  }
}

/*******************************************************************************
 * Node titles
 */

span.fancytree-title {
  .border-radius(0);
  border: 1px solid transparent; // reserve some space for status borders
  white-space: normal;
  word-wrap: break-word;
  line-height: @ax-spacing-l;
  margin-top: 0.3rem;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;

  .screen-md-portrait-min({
    margin-top: 0;
    margin-left: @ax-spacing-xxs;
  });
}

table.fancytree-container {
  .icon-info_sign {
    vertical-align: middle;
    text-decoration: none;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  span.fancytree-title,
  ul.fancytree-plain span.fancytree-title {
    background-color: transparent;
    display: inline-block;
    width: auto;
  }
}

tr,
span {

  &.fancytree-selected,
  &.fancytree-active {
    span.fancytree-title {
      color: @black;
      font-style: normal;
    }
  }

  &.fancytree-unselectable span.fancytree-title {
    color: @fancy-font-color-dimm;
    font-weight: lighter;
    background-color: transparent;
    cursor: default;
  }
}

/*******************************************************************************
* Node connector
*/

span.fancytree-connector {
  background-position: -16px -63px;
}

/*******************************************************************************
* Status node - no data
*/
tr.fancytree-statusnode-nodata,
span.fancytree-statusnode-nodata {
  display: block;
  background-color: transparent;
  text-align: center;
  font-size: @topic-font-size;

  span.fancytree-expander {
    display: none;
  }

  span.fancytree-title {
    font-weight: @ax-font-weight-regular;
    font-size: 2.2rem;
    color: @meta-color;
    cursor: default;
  }
}

span.fancytree-statusnode-nodata {
  margin-top: 7rem;
}

span.fancytree-custom-icon {
  margin-top: 4px;
  margin-left: 8px;
  font-size: @fancy-icon-width;
  line-height: 1.4rem;
}

.fancytree-viewport-with-scrollbar {
  height: 100%;
  .display-flex();

  .fancytree-grid-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background: @white;
    }

    &::-webkit-scrollbar-thumb {
      // this color has to be the same used by the PlainScrollbar JavaScript Library
      // check the file plain-scrollbar.css
      background: rgba(165, 165, 165, 0.9);
    }
  }

  .fancytree-grid-container--hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .fancytree-vertical-scrollbar {
    position: relative;
    height: auto;

    &[data-visible="true"] {
      display: block;
    }

    &[data-visible="false"] {
      display: none;
    }
  }
}
